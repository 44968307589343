import React, { useState, useEffect } from "react";
import { useKeyPressEvent } from "react-use";
import { Button, ModalBackdrop, ModalContent } from "./styles";
import Header from "./components/Header";
import "./roboDashboard.css";
import {
	FaArrowUp,
	FaArrowLeft,
	FaArrowRight,
	FaArrowDown,
	FaStop,
	FaCamera,
} from "react-icons/fa";

const Control = ({
	onClose,
	onLeft,
	onRight,
	onUp,
	onDown,
	cameraUp,
	cameraDown,
}) => {
	useKeyPressEvent("Escape", onClose);
	useKeyPressEvent("a", onLeft);
	useKeyPressEvent("d", onRight);
	useKeyPressEvent("w", onUp);
	useKeyPressEvent("s", onDown);
	useKeyPressEvent("q", cameraUp);
	useKeyPressEvent("e", cameraDown);

	return (
		<div className="control-container">
			<div className="video-control-control">
				<button>
					<FaStop />
				</button>
				<button>
					<FaArrowUp />
				</button>
				<button>
					<FaArrowLeft />
				</button>
				<button>
					<FaArrowRight />
				</button>
				<button>
					<FaArrowDown />
				</button>
			</div>
			<div className="camera-control-control">
				<button>
					<FaArrowUp />
				</button>
				<FaCamera />
				<button>
					<FaArrowDown />
				</button>
			</div>
		</div>
	);
};

// const Modal = function ({ onClose, onLeft, onRight, onUp, onDown, onEnter }) {
// 	useKeyPressEvent("Escape", onClose);
// 	useKeyPressEvent("a", onLeft);
// 	useKeyPressEvent("d", onRight);
// 	useKeyPressEvent("w", onUp);
// 	useKeyPressEvent("s", onDown);

// 	return (
// 		// <ModalBackdrop>
// 		// 	<ModalContent>
// 		// 		<>
// 		// 			<button onClick={onClose}>Stop</button>
// 		// 			<button onClick={onLeft}>Left</button>
// 		// 			<button onClick={onRight}>Right</button>
// 		// 			<button onClick={onUp}>Up</button>
// 		// 			<button onClick={onDown}>Down</button>
// 		// 		</>
// 		// 	</ModalContent>
// 		// </ModalBackdrop>
// 		<>Control goes here</>
// 	);
// };

const sendMessage = (ws, message) => {
	ws.send(message);
};

export default function RoboDashboard() {
	const [ws, setWs] = useState(null);
	const [name, setName] = useState("");

	useEffect(async () => {
		if (name) {
			const webSocket = new WebSocket(
				`wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=${name}`
			);
			console.log("Connecting to websocket");
			console.log(webSocket);
			setWs(webSocket);
		}
	}, [name]);

	const apiCall = {
		action: "message",
		data: "right",
	};
	const apiCall1 = {
		action: "message",
		data: "left",
	};
	const apiCall2 = {
		action: "message",
		data: "forward",
	};
	const apiCall3 = {
		action: "message",
		data: "stop",
	};
	const apiCall4 = {
		action: "message",
		data: "connect",
	};
	const apiCall5 = {
		action: "message",
		data: "cameraUp",
	};
	const apiCall6 = {
		action: "message",
		data: "cameraDown",
	};

	// ws.onopen = (event) => {
	// 	event.preventDefault();
	// 	if (!wsConnect) {
	// 		setWsConnect(true);
	// 		console.log("Connected to server ");
	// 	}
	// };
	const checkConnection = () => {
		if (ws !== null) {
			ws.onopen = (event) => {
				console.log("Connected from server");
			};
		}
	};

	if (ws !== null) {
		ws.onmessage = function (event) {
			const json = JSON.parse(event.data);
			console.log(json);
			try {
				if ((json.event = "data")) {
					// setBids(json.data.bids.slice(0, 5));
				}
			} catch (err) {
				console.log(err);
			}
		};
	}

	const [isConnected, setConnected] = useState(false);
	const [liveData, setLiveData] = useState("");
	const [roboId, setRoboId] = useState(null);

	useEffect(() => {
		console.log(liveData, roboId);
	}, [liveData]);

	const changeData = (e) => {
		const inputData = e.target.value;
		setLiveData(inputData);
	};

	return (
		<div className="Robo">
			{!isConnected ? (
				<>
					<Header
						navigation1="Dashboard"
						navigation2="Profile"
						threeBars="true"
					/>
					<div className="row-center-even robocontrol-container">
						<div className="robocontrol-heading">
							Welcome to CROBOT MaTron Control page!
						</div>
						<div className="row-center-even robocontrol-data-field">
							<div className="data-input">
								<input
									type="text"
									name=""
									id="roboData"
									onChange={changeData}
								/>
							</div>
							<div className="data-submit">
								<button
									type="submit"
									onClick={async (e) => {
										setName(liveData);
										ws.send(JSON.stringify(apiCall4));
										e.preventDefault();
										setRoboId(liveData);
										setConnected(true);
									}}
								>
									Connect!
								</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="row-center-even video-control-container">
					<div className="video-control-meet">
						<iframe
							allow="camera; microphone; fullscreen; speaker; display-capture"
							src="https://justfortria.yourvideo.live/626d23f1f183f8570398a4f9"
						></iframe>
						<Control
							onClose={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall3)) : null
							}
							onLeft={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall1)) : null
							}
							onRight={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall)) : null
							}
							onUp={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall2)) : null
							}
							onDown={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall3)) : null
							}
							cameraUp={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall5)) : null
							}
							cameraDown={() =>
								ws ? sendMessage(ws, JSON.stringify(apiCall6)) : null
							}
						/>
					</div>
				</div>
			)}
			{/* <Modal onClose={() => ws.send(JSON.stringify(apiCall3))} />
				<Modal onLeft={() => ws.send(JSON.stringify(apiCall1))} />
				<Modal onRight={() => ws.send(JSON.stringify(apiCall))} />
				<Modal onUp={() => ws.send(JSON.stringify(apiCall2))} />
				<Modal onDown={() => ws.send(JSON.stringify(apiCall3))} /> */}
		</div>
	);
}

// function App() {
// 	//give an initial state so that the data won't be undefined at start
// 	// const [bids, setBids] = useState([0]);

// 	const ws = new WebSocket(
// 		"wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=mahaarun"
// 	);

// 	const apiCall = {
// 		action: "message",
// 		data: "right",
// 	};

// 	ws.onopen = (event) => {
// 		ws.send(JSON.stringify(apiCall));
// 		console.log("Connected to server");
// 	};

// 	ws.onmessage = function (event) {
// 		const json = JSON.parse(event.data);
// 		console.log(json);
// 		try {
// 			if ((json.event = "data")) {
// 				// setBids(json.data.bids.slice(0, 5));
// 			}
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	};
// 	//map the first 5 bids
// 	// const firstBids = bids.map((item) => {
// 	// 	return (
// 	// 		<div>
// 	// 			<p> {item}</p>
// 	// 		</div>
// 	// 	);
// 	// });

// 	return <div>Helloworld</div>;
// }

// export default App;
