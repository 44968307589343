import "./App.css";
import Header from "./components/Header";
import OurServices from "./OurServices";
import ElasticCarousel from "./ElasticCarousel";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./components/Footer";
import SignUp from "./SignUp";
import crobot_video from "./videos/crobot_video.mp4";
import Home from "./Home";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import UpcomingEvents from "./UpcomingEvents";
import RoboDashboard from "./RoboDashboard";
function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<RoboDashboard />} />
				<Route path="/Signup" element={<SignUp />} />
				<Route path="/Dashboard" element={<Dashboard />} />
				<Route path="/Profile" element={<Profile />} />
				<Route path="/Upcoming" element={<UpcomingEvents />} />
			</Routes>
		</div>
	);
}

export default App;
