import Circular_Subscription from "./Circular_Subscription";
import './Dashboard_Component.css'
import UnderMaintenance from "./UnderMaintenance";
import UserBrief from "./UserBrief";

function Dashboard_Component() {
    return (
        <>
        <h2 id="dashboard_h2">Dashboard</h2>
        <div id="count_label">
            TOTAL ROBOTS : <span id="count">3</span>
            <br/>
            IN USE : <span id="count">0</span>
            <button>INVOICES</button>
        </div>
        <UserBrief />
        <Circular_Subscription />
        {/* <UnderMaintenance /> */}
        </>
    );
}

export default Dashboard_Component;