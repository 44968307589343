import Header from "./components/Header";
import OurServices from "./OurServices";
import ElasticCarousel from "./ElasticCarousel";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./components/Footer";
import crobot_video from "./videos/crobot_video.mp4";
function Home() {
	return (
		<>
			<Header navigation1="Dashboard" navigation2="Profile" threeBars="true" />
			<div id="video">
				<video src={crobot_video} autoPlay loop muted />
			</div>

			{/*Our Services */}
			<OurServices />

			{/* <ImgCarousel /> */}
			<ElasticCarousel />
			<AboutUs />
			<ContactUs />
			<Footer />
		</>
	);
}
export default Home;
