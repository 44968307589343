import React from "react";
import "./Yellow_line.css";

function Yellow_line(props) {
	return (
		<h2 className="yellow_line">
			<span className="titles">{props.title}</span>
		</h2>
	);
}
export default Yellow_line;
