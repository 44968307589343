import './UnderMaintenance.css';

function UnderMaintenance(){
    return(
        <div className="under-maintenance">
            <h2>UNDER MAINTENANCE</h2>
            <img src={require('../icons/website-maintenance.png')} alt="maintenance" />
            <p>This page is under maintenance. Sorry for the inconvinience caused.</p>
        </div>
        );
}

export default UnderMaintenance;