import UnderMaintenance from "./UnderMaintenance";

function UpcomingComponent() {
    return (
        <>
            <h1>Upcoming Events</h1>
            <UnderMaintenance />
        </>
    );
}
export default UpcomingComponent;
