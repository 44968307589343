import Dashboard_Component from "./components/Dashboard_Component";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Yellow_line from "./components/Yellow_line";
import { useAuth } from "./AuthContext";

import './Dashboard.css';

const rand_slno= () => {
    var str="";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for( var i=0; i < 16; i++ )
        str += possible.charAt(Math.floor(Math.random() * possible.length));
    return str;
}

const models = ["ALPHA", "BETA", "GAMMA", "DELTA", "EPSILON"];
const bot_items = [];
for (let i=0;i<7;i++) {
    bot_items.push(
    <tr>
        <td>{i+1}</td>
        <td>MODEL {models[(3*i+11)%5]}</td>
        <td>{rand_slno()}</td>
        <td><button className="manage_btn">MANAGE</button></td>
    </tr>
    );
}

const event = ["Event 1", "Event 2", "Event 3", "Event 4", "Event 5"];
const venue = ["Hyderabad", "Bangalore", "Chennai", "Pune", "Mumbai"];
const event_items = [];

for (let i=0;i<9;i++) {
    event_items.push(
    <tr>
        <td>{i+1}</td>
        <td>{event[i%5]}</td>
        <td>{venue[i%5]}</td>
        <td>{(((i+1)*247)%24)+1}:00 IST</td>
        <td>{(((i+1)*247)%28)+1}-{(((i+1)*247)%12)+1}-2022</td>
    </tr>
    );
}


function Dashboard() {
    const { user} = useAuth();
	const { isAuthenticated } = user;
        if(isAuthenticated){
        return(
            <>
                <Header navigation1="" navigation2="Profile"/>
                <Dashboard_Component />
                <br/>
                <Yellow_line title="MY BOTS" />
                <table className="bot_list">
                    <th>SL. NO.</th>
                    <th>MODEL</th>
                    <th>SERIAL NO.</th>
                    <th></th>
                    {bot_items}
                </table>

                <Yellow_line title="MY EVENTS" />
                <table className="bot_list">
                    <th>SL. NO.</th>
                    <th>EVENT</th>
                    <th>VENUE</th>
                    <th>START TIME</th>
                    <th>DATE</th>
                    {event_items}
                </table>
                <Footer />
            </>

        );
    }
    else{
        return(
            <>
                <Header/>
                <h2>No Access without login</h2>
                <Footer />
            </>

        );
    }
}

export default Dashboard;