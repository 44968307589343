import { createContext, useContext, useState, useEffect } from "react";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAUpbO1eSY8LMOink-cdUl3jSP7qV83cgQ",
	authDomain: "website-a62d0.firebaseapp.com",
	projectId: "website-a62d0",
	storageBucket: "website-a62d0.appspot.com",
	messagingSenderId: "193042281896",
	appId: "1:193042281896:web:5be824ae34c5c3c69d599d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({
		name: localStorage.getItem("name") || "",
		email: localStorage.getItem("email") || "",
		photo: localStorage.getItem("photo") || "",
		isAuthenticated: localStorage.getItem("name") ? true : false,
	});
	const signInWithGoogle = () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				const name = result.user.displayName;
				const email = result.user.email;
				const photo = result.user.photoURL;
				setUser({
					name: name,
					email: email,
					photo: photo,
					isAuthenticated: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const logout = () => {
		auth
			.signOut()
			.then(() => {
				localStorage.clear();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		localStorage.setItem("name", user.name);
		localStorage.setItem("email", user.email);
		localStorage.setItem("photo", user.photo);
	}, [user]);
	return (
		<AuthContext.Provider value={{ user, setUser, signInWithGoogle, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
export const useAuth = () => useContext(AuthContext);
