import React from "react";
import './Circular_Subscription.css'

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {
    buildStyles
  } from "react-circular-progressbar";
  import AnimatedProgressProvider from "./AnimatedProgressProvider";
  import { easeQuadInOut } from "d3-ease";

  var daysLeft= 0
  var subscriptionDays= 84


  let date_1 = new Date('9/16/2022');
  let date_2 = new Date();
  
  const days = (date_1, date_2) =>{
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDays;
  }


  function Circular_Subscription(){
    daysLeft= days(date_1, date_2);
    return(
        <div id="subscription_container">
            <AnimatedProgressProvider
            valueStart={0}
            valueEnd={daysLeft}
            duration={1.4}
            easingFunction={easeQuadInOut}
            >
            {value => {
            const roundedValue = Math.round(value);
            
            return (
                <CircularProgressbarWithChildren
                value={value}
                maxValue={subscriptionDays}
                
                /* This is important to include, because if you're fully managing the
            animation yourself, you'll want to disable the CSS animation. */
                styles={buildStyles({ pathTransition: "none", pathColor: "#32CD32",trailColor: "#012D70" })}
                >
                    <div id="subscription_text">
                        {daysLeft+"/"}
                        <span>{subscriptionDays}</span>
                    </div>
                    <div id="days_left">DAYS LEFT</div>
                </CircularProgressbarWithChildren>
            );
            }}
        </AnimatedProgressProvider>
        <div id="below_subscription">
            SUBSCRIPTION<br />
            <a href="">view or edit plans</a>
        </div>
      </div>
    );
  }
export default Circular_Subscription;