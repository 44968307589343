import './Footer.css'
import ct from '../icons/transparent_blue.png'

function Footer() {
  return (
    <div id="foot">
    <h3>WE CARE & WE ASSIST</h3>
        <div id="crobot_logo">
            <img src={ct} id="crobot_logo" />
        </div>
    <p>COPYRIGHTS © 2022 CROBOT TECHNOLOGIES PVT. LTD.</p>
</div>
  );
}
export default Footer;