import Footer from "./components/Footer";
import Header from "./components/Header";
import Profile_Component from "./components/Profile_component";

import './Profile.css';

var flag=0;

const edit_profile = () => {
    if(flag==0){
        //document.getElementById("address").readOnly = false;
        console.log("edit");
        flag=1;
        // document.getElementById("edit_profile").value = "false";
    }
    else{
        flag=0;
        console.log("edit11");
        //document.getElementById("address").readOnly = true;
    }
}

function Profile(){
    return(
        <>
            <Header navigation1="" navigation2="Dashboard" />
            <Profile_Component />
            <div id="profile_wrapper">
                <div>
                    <h3>PROFILE</h3>
                    <div className="profile_div">
                        <div>First Name </div><input type="text" id="fname" className="profile_input" value={"John"} readOnly /><br/>
                        <div>Last Name </div><input type="text" id="lname" className="profile_input" value={"Doe"} readOnly /><br />
                        <div>Email </div><input type="text" id="email" className="profile_input" value={"John@doe.com"} readOnly /><br />
                        <div>Phone </div><input type="number" id="phone" className="profile_input" value={919292929292} readOnly />
                    </div>
                    <div className="profile_div">
                        <div>First Name </div><input type="text" id="fname" className="profile_input" value={"John"} readOnly /><br/>
                        <div>Last Name </div><input type="text" id="lname" className="profile_input" value={"Doe"} readOnly /><br />
                        <div>Address </div><textarea id="address" className="profile_input" value={"Street A, Thanjavur, Tamilnadu, India"} rows="3" readOnly ></textarea><br />
                    </div>
                </div>
                <button id="edit_profile" onClick={edit_profile()}>EDIT PROFILE</button>
            </div>
            <Footer />
        </>
    );
}

export default Profile;