import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { HashLink } from 'react-router-hash-link';
import ct from "../icons/transparent_blue.png";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// import { logout } from "../firebase";

import Avatar from "@mui/material/Avatar";
function Header(props) {
	// const { loginbtn = "false" } = props.loginbtn;
	const { user, setUser, logout } = useAuth();
	const { isAuthenticated } = user;
	const isLoggedIn = localStorage.getItem("name") ? true : false;
	const navigate = useNavigate();

	var user_menu_open= false;
	function toggleUserMenu(){
		if(user_menu_open=== false){
			document.getElementById("userToggleMenu").style.right= "1vw";
			user_menu_open= true;
		}
		else{
			document.getElementById("userToggleMenu").style.right= "-20vw";
			user_menu_open= false;
		}
	}

	var side_menu_open= false;
	function toggleSideMenu(){
		if(side_menu_open=== false){
			document.getElementById("sideMenu").style.left= "1vw";
			side_menu_open= true;
		}
		else{
			document.getElementById("sideMenu").style.left= "-20vw";
			side_menu_open= false;
		}
	}
	// document.getElementsByTagName("body").onClick= function(){
	// 	if(user_menu_open=== true)
	// 		toggleUserMenu();
	// 	if(side_menu_open=== true)
	// 		toggleSideMenu();
	// }
	// $('html').click(function() {
	// 	if(open== true){
	// 		document.getElementById("userToggleMenu").style.right= "-10vw";
	// 		open= false;
	// 	}
	// });
	// $('login_info').click(function(event){
    // event.stopPropagation();
	// });
	
	const imageUrl = user.photo;
	return (
		<div className="navbar">
			<FontAwesomeIcon icon={faBars} id="three_bars" 
				onClick={()=>{
					toggleSideMenu();
					if(user_menu_open=== true)
						toggleUserMenu();
					}
				}
					style={{visibility:props.threeBars?"visible":"hidden"}}
			/>
			
			<h3 id="header_h3">
				<img src={ct} alt="ct" id="header_ct" />
				CROBOT TECHNOLOGIES PRIVATE LIMITED
			</h3>
			<div className="avatar-btn-container">
				<button
					onClick={() => {
							navigate("/signup");
					}}
					value={"LOGIN"}
					id={"login_btn"}
					// style={{ display: loginbtn }}
					className="login-nav"
					style={{display: isAuthenticated ? "none" : "block"}}
				>
					Login
				</button>
				<div
					id="login_info"
					style={{position:"absolute", right:"1vw"}}
					onClick={()=>{
						toggleUserMenu();
						if(side_menu_open=== true)
							toggleSideMenu();
						}
					}
				>
					{ isAuthenticated ? <>{user.name}<Avatar style={{display:"inline-block", marginLeft:"0.7vw"}} src={imageUrl} /></> : null}
				</div>
			</div>
			<div id="sideMenu">
				<HashLink to="#services" className="intLink">Services</HashLink><hr />
				<HashLink to="#elastic_carousel" className="intLink">Gallery</HashLink><hr />
				<HashLink to="#about_us" className="intLink">About Us</HashLink><hr />
				<HashLink to="#contact" className="intLink">Contact</HashLink><hr />
				<div
					onClick={()=>{
						navigate("/Upcoming");
						}
					}
				>
					Upcoming Events
				</div>
				

			</div>
			<div id="userToggleMenu">
				<div
					onClick={() => {
						navigate("/"+props.navigation1);
					}
					}
				>
					{props.navigation1?props.navigation1:"Home"}
				</div>
				<hr />
				<div
					onClick={() => {
						navigate("/"+props.navigation2);
					}}
				>
					{props.navigation2}
				</div>
				<hr />
				<div
					onClick={() => {
						navigate("/");
						if (isAuthenticated) {
							logout();
							navigate("/");
							setUser({
								name: "",
								email: "",
								photo: "",
								isAuthenticated: false,
							});
						}
						toggleUserMenu();
					}}
				>
					Logout
				</div>
			</div>
		</div>
	);
}
export default Header;
