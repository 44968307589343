import { useState, useEffect } from "react";
import "./SignUp.css";
import Header from "./components/Header";
// import { signInWithGoogle } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
function SignUp() {
	const initialValues = { username: "", email: "", password: "" };
	const [formValues, setFormValues] = useState(initialValues);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const navigate = useNavigate();
	const { signInWithGoogle, user } = useAuth();
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setFormErrors(validate(formValues));
		setIsSubmit(true);
	};

	useEffect(() => {
		if (user.isAuthenticated) {
			navigate("/");
		}
	}, [user]);
	const validate = (values) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		if (!values.username) {
			errors.username = "Username is required!";
		}
		if (!values.email) {
			errors.email = "Email is required!";
		} else if (!regex.test(values.email)) {
			errors.email = "This is not a valid email format!";
		}
		if (!values.password) {
			errors.password = "Password is required";
		} else if (values.password.length < 8) {
			errors.password = "Password must of atleast 8 characters";
		} else if (values.password.length > 10) {
			errors.password = "Password cannot exceed more than 10 characters";
		}
		return errors;
	};

	return (
		<>
		<Header />
		<div className="signin_container">
			<button onClick={signInWithGoogle} className="fluid ui button blue">
				Signin with Google
			</button>
		</div>
		</>
	);
}

export default SignUp;
