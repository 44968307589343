import { useAuth } from "../AuthContext";


function UserBrief(){
    const { user } = useAuth();
    return(
        <div id="user_brief">
            <img src={user.photo} alt={"picture of "+user.name} id="user_img" />
            <h2>{user.name}</h2>
            <div>{user.email}</div>   
        </div>
    );
}
export default UserBrief;